import React, {useState, useRef, useEffect} from 'react'

import ActionCreators from '../../actions';
import Checkout from '../../Screens/CheckoutScreen';
import {connect, useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import { useToasts } from 'react-toast-notifications';
import {setCompanies} from "../../utils/functions";
// import {getPaymentPriceInfoCheckout} from "../../actions/payment";
import {useHistory} from "react-router-dom";

import axios from "axios";
import {getInspectionDiscount} from "../../actions/vehicleInspection";
import {add} from "react-modal/lib/helpers/classList";
// import {getInspectionDetail} from "../../actions/vehicleInspection";

const CheckOutContainer = (props) => {

  const {
    getInspectionDiscount,

    getInspectionDetail,
  } = props;

  const [isCheckRadio, setIsCheckRadio] = useState(false);
  // const [loading, setLoading] = useState();
  const [valuePromo,setValuePromo] = useState('');
  const [valueReferral,setValueReferral] = useState('');
  const [price, setPrice] = useState(false);
  const [paymentStatusCheckout, setPaymentStatusCheckout] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [getInspectionDetailData, setGetInspectionDetailData] = useState('');
  const [vehicleStatusLoading, setVehicleStatusLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [isSurveyModalVisible, setSurveyModal] = useState(false);

  const [surveyCheck, setSurveyCheck] = useState(false);

  const inputElt = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {

    const { match } = props;

  axios
      .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/review/status/${match?.params?.id}`)
      .then((resp) => {
            setPaymentStatusCheckout(resp.data.payment_status);
          }
      )
      .catch((err) => {

      });


  }, []);



  const {match} = props;
  const inspectId = match?.params?.id;
  const vehicle_Id = match?.params?.vehicleId;
  const hasAddedValue = localStorage.getItem('hasAdded');

  useEffect(() => {


    const user = JSON.parse(localStorage.getItem('currentUser'));
    const { getPaymentPriceInfoCheckout, match } = props;
    const promoCode = localStorage.getItem('promoCode');
    // const referral=localStorage.getItem('ReferralCode');

    const chkType = "firstTime"
    setValuePromo(promoCode);
    // setValueReferral(referral)


      getPaymentPriceInfoCheckout(inspectId, setPrice, setPriceLoading, promoCode, setValuePromo, addToast, chkType,
        // referral,setValueReferral
        );

  }, [inspectId , vehicle_Id]);

useEffect(()=> {


  getInspectionDetail(match?.params?.id, setGetInspectionDetailData, setDetailLoading);


}, []);

  const inspectionDataEdit = {
    InspectionId:getInspectionDetailData?.id ,
    VehicleId:getInspectionDetailData?.Vehicle?.id ,
    selectedCompanies: getInspectionDetailData?.CompanyInspections?.map((data) => data.Company.id),
    plateNumber:getInspectionDetailData?.Vehicle?.licensePlateNumber ,
    state: getInspectionDetailData?.inspectionState,
  }

  const discountCheck = (checkValue) => {
    if(checkValue === 7){
    localStorage.setItem("isUberComapany" , false);
    }
    setIsCheckRadio(!isCheckRadio);

    const body = {
      "hasDiscount": true,
      "companies": [checkValue],

    }
    const chkType = "firstTime";
      getInspectionDiscount(match?.params?.vehicleId, checkValue,match?.params?.id, setPriceLoading, body, setPrice, valuePromo, setGetInspectionDetailData, addToast, setValuePromo, chkType, setDetailLoading,
        // valueReferral,setValueReferral
      );
      
  }

const priceTotal ={
    totalPrice: price?.total,
}

  const handlePaymentTransaction = () => {
      history.push(`/transactionScreen/${match?.params?.id}/${getInspectionDetailData?.Vehicle?.id}`, priceTotal);

  };


  const handlePromoData = (code) => {

    setValuePromo(code);

  }

  // const handleReferralData = (code) => {

  //   setValueReferral(code);

  // }

  const handleChangeVehicleStatus = () => {

    const { changeVehicleStatus, match } = props;
    changeVehicleStatus(match?.params?.id, match?.params?.vehicleId, setVehicleStatusLoading, history);
  };

  const handleSkipPayment = (paymentStatusCheckout) => {


    const { match, skipPaymentMethod } = props;
    skipPaymentMethod(match?.params?.id, match?.params?.vehicleId, setPriceLoading, history, paymentStatusCheckout);
  }


  const submitPromo = () => {

    localStorage.setItem('promoCode', valuePromo);
    const { getPaymentPriceInfoCheckout, match } = props;

      getPaymentPriceInfoCheckout(match?.params?.id, setPrice, setPriceLoading,valuePromo, setValuePromo, addToast,'',
      // valueReferral,setValueReferral
      );


  }
  // const submitReferral = () => {

  //   localStorage.setItem('ReferralCode', valueReferral);
  //   const { getPaymentPriceInfoCheckout, match } = props;
  //   getPaymentPriceInfoCheckout(match?.params?.id, setPrice, setPriceLoading,valuePromo, setValuePromo, addToast,'',valueReferral,setValueReferral);


  // }

  const clearPromoCode = (data) => {

    setValuePromo(data);
    localStorage.removeItem('promoCode');
    const chkType = "deletePromo";
    const { getPaymentPriceInfoCheckout, match } = props;
    getPaymentPriceInfoCheckout(match?.params?.id, setPrice, setPriceLoading,data, setValuePromo, addToast, chkType,
      // valueReferral,setValueReferral
      );

  }
  // const clearReferralCode = (data) => {

  //   setValueReferral(data);
  //   localStorage.removeItem('ReferralCode');
  //   const chkType = "deletereferral";
  //   const { getPaymentPriceInfoCheckout, match } = props;
  //   getPaymentPriceInfoCheckout(match?.params?.id, setPrice, setPriceLoading,valuePromo, setValuePromo, addToast, chkType,data,setValueReferral);

  // }

  const clearPromoValue = () => {
    setValuePromo('');
  }
  // const clearReferralValue = () => {
  //   setValueReferral('');
  // }

  return (
    <>
    <Checkout
        history={history}
        isCheckRadio={isCheckRadio}
        discountCheck={discountCheck}
        inputElt={inputElt}
        valuePromo={valuePromo}
        // valueReferral={valueReferral}
        handlePromoData={handlePromoData}
        submitPromo={submitPromo}
        price={price}
        paymentStatusCheckout={paymentStatusCheckout}
        clearPromoCode={clearPromoCode}
        priceLoading={priceLoading}
        clearPromoValue={clearPromoValue}
        handleSkipPayment={handleSkipPayment}
        changeVehicleStatus={handleChangeVehicleStatus}
        vehicleStatusLoading={vehicleStatusLoading}
        inspectionDataEdit={inspectionDataEdit}
        handlePaymentTransaction={handlePaymentTransaction}
        isSurveyModalVisible={isSurveyModalVisible}
        checkHasAddedData = {props?.location?.state?.hasAddedData}
        hasAddedValue={hasAddedValue}
        // handleReferralData={handleReferralData}
        // submitReferral={submitReferral}
        // clearReferralValue={clearReferralValue}
        // clearReferralCode={clearReferralCode}

    />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehicleData: state.auth.vehicleData,
    isLoading: state.auth.isAuthLoading,
    companies: setCompanies(state.auth.companies),
    vehicleStatus: state.vehicleInstruction.vehicleStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutContainer);
